<template>
    <span>
        <table inline style="width: auto; border-left: 1px solid gray; border: 1px solid gray;
        padding: 0 7px 0 7px; background-color: #ffdf00; white-space: nowrap; border-radius: 3px;">
          <tr>
            <td style="margin: 0 4px 0 3px; font-weight: bold" v-bind:style="{color: openColor}">open:</td>
            <td>
                <b-form-datepicker v-model="open" size="sm" class="datetimepicker"
                     :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                      locale="en"
                      style="border: 1px solid gray;"
                      v-bind:style="{color: openColor}"></b-form-datepicker>
            </td>
          </tr>
          <tr>
            <td style="margin: 0 4px 0 3px; font-weight: bold" v-bind:style="{color: closeColor}">close:</td>
            <td>
                <b-form-datepicker v-model="close" size="sm" 
                      :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                      locale="en"
                      style="border: 1px solid gray;"
                      v-bind:style="{color: closeColor}"></b-form-datepicker>
            </td>
          </tr>
        </table>

    </span>
</template>

<style scoped>
table { 
   /* border-spacing: 2px;*/
   
    border-collapse: separate;
}
</style>

<script>

    import moment from 'moment';

    export default {
        created() {
            if (this.open === "") {
                let today = moment().format("YYYY-MM-DD");
                console.log("today=", today);
                this.open = today;
            }
           // console.log("this.close=", this.close);
            if (this.close === "") {
                this.close = moment().add(1, 'months').format("YYYY-MM-DD");
            }
        },
        mounted() {
           // console.log("open-close-selector.vue starting. this.namespace=", this.namespace);
        },
        props: ['namespace'],
        computed: {
         /*   maximumOpen() {
                let maximumOpen = moment().add(364, 'days').format('YYYY-MM-DD');
               // console.log("maximumOpen=", maximumOpen);
                return maximumOpen;
            },
            minimumClose() {
                let minimumClose = moment(this.open).add(1, 'days').format('YYYY-MM-DD');
               // console.log("minimumClose=", minimumClose);
                return minimumClose;
            },
            maximumClose() {
                let maximumClose = moment(this.$store.state[this.namespace].open).add(364, 'days').format('YYYY-MM-DD');
               // console.log("maximumClose=", maximumClose);
                return maximumClose;
            },*/
            open: {
                get() {
                    // let open = moment(this.$store.state[this.namespace].open);
                    return this.$store.state[this.namespace].open;
                },
                set(open) {
                    this.$store.commit(this.namespace + '/setOpen', open);
                }
            },
            close: {
                get() {
                    return this.$store.state[this.namespace].close;
                },
                set(close) {
                    console.log("close=", close);
                    this.$store.commit(this.namespace + '/setClose', close);
                }
            },
            openColor(){
                    return this.$store.state[this.namespace].buySell === "buy" ? "green" : "red";
            },
            closeColor(){
                    return this.$store.state[this.namespace].buySell === "buy" ? "red" : "green";
            }
        }
    }
</script>
