<template>
    <div>
        <table border=0 style="font-weight: bold; border: 1px solid gray; color: black; background-color: #e7edf5; margin: 0 11px 0 -8px;
                     padding: 1px 0 1px 0; width: auto">
            <tr>
                <td style="text-align: center">
                    <b-form-group label="" v-slot="{ ariaDescribedby }" v-if="showTradingPeriod || showPlaybackControl || program === 'Calculator'"
                            style="margin: 4px 1px 2px 5px; font-weight: bold;">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="buySell"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    size="sm"></b-form-radio-group>
                  </b-form-group>
                </td>
                <td v-if="program !== 'Calculator'" >
                  <toggle-single-throw v-bind:namespace="namespace" 
                    v-bind:property="'showTradingPeriod'" v-bind:label="'period'" 
                    style="margin: 0 5px 0 3px;"></toggle-single-throw>
            </td>
            </tr>
            <tr v-if="showTradingPeriod || showPlaybackControl || program === 'Calculator'">
                <td v-if="program === 'Calculator'" colspan="2" style="margin: 2px 0 3px 0px">       
                    <open-close-selector-no-year v-bind:namespace="namespace" style="width: 100%"></open-close-selector-no-year>
                </td>
                <td v-if="program !== 'Calculator'" colspan="2" style="padding: 3px 2px 0px 2px">       
                    <open-close-selector v-bind:namespace="namespace" style=""></open-close-selector>
                </td>
            </tr>
        </table>

    </div>
</template>

<style scoped>
table {
    border-collapse: separate;
    border-radius: 3px;
}
</style>

<script>
    import openCloseSelector from '@/components/open-close-selector';
    import openCloseSelectorNoYear from '@/components/open-close-selector-no-year';
    import toggleSingleThrow from '@/components/toggle-single-throw';

    export default {
        components: {
            openCloseSelector, toggleSingleThrow, openCloseSelectorNoYear
        },
        mounted() {
          // console.log("trade-specs.vue mounted() starting.");
           // console.log("this.namespace=", this.namespace);
        },
        props: ['namespace', 'title'],
        data() {
            return {
                selected: 'first',
                options: [
                    {text: 'buy', value: 'buy'},
                    {text: 'sell', value: 'sell'}
                ]
            }
        },
        computed: {
            program(){
                return this.$store.state[this.namespace].program;
            },
            buySell: {
                get() {
                    return this.$store.state[this.namespace].buySell;
                },
                set(buySell) {
                    this.$store.commit(this.namespace + '/setBuySell', buySell);
                }
            },
            showTradingPeriod(){
                return this.$store.state[this.namespace].showTradingPeriod;
            },
            showPlaybackControl(){
                return this.$store.state[this.namespace].showPlaybackControl;
            }
        }
    }
</script>

